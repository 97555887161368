import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  contactUs: `/register/contact_us`, // 联系我们
  country: `/register/countryCity` // 国家 省  城市
}

// 联系我们
export const getContactUs = (params) => fetch(`${apiUrl.contactUs}/${lang}/${site}`, params, 'post')
// 国家 省  城市
export const getRegion = (params) => fetch(`${apiUrl.country}/${lang}/${site}` + `?region=${params.region}`)
export const getCountry = (params) => fetch(`${apiUrl.country}/${lang}/${site}` + `?country=${params.country}`)
export const getprovice = (params) => fetch(`${apiUrl.country}/${lang}/${site}` + `?province=${params.province}`)

