import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  newsletter: `/register/newsletter_subscript` // 邮件订阅
}

// 邮件订阅
export const getNewsletter = (params) => fetch(`${apiUrl.newsletter}/${lang}/${site}`, params, 'post')

